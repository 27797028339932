export const toggleMobileSearch = (value: any) => ({
   type: 'TOGGLE_MOBILE_SEARCH',
   value,
});

export const updateWorkbench = (userTopics: any) => ({
   type: 'UPDATE_WORKBENCH',
   userTopics,
});

export const updateNotifications = (notifications: any) => ({
   type: 'UPDATE_NOTIFICATIONS',
   notifications,
});

export const toggleMobileMenu = () => ({
   type: 'TOGGLE_MOBILE_MENU',
});
