import styled from 'styled-components';
import { shadow, space, fontWeight } from '@ifixit/primitives';

export const DropdownContainer = styled.div`
   display: flex;
   flex-direction: column;
   background-color: ${({ theme }) => theme.background};
   box-shadow: ${shadow[2]};
`;

export const CardColumnContainer = styled.div`
   display: flex;
   justify-content: space-between;
   padding-block: ${space[5]};
`;

export const CardColumn = styled.div`
   display: flex;
   flex-flow: column nowrap;
`;

export const CardLink = styled.a`
   padding: ${({ theme }) => theme.cardPadding} 0;
   display: flex;
   align-items: center;
   line-height: ${space[3]};
   font-weight: ${fontWeight.bold};
   white-space: nowrap;

   &:hover {
      background-color: transparent;
      text-decoration: none;
   }
`;
