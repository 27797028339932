import type { MouseEventHandler, KeyboardEventHandler, ReactNode, ComponentType } from 'react';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { shadow } from '@ifixit/primitives';

const DropdownContainer = styled.div`
   position: relative;
   display: flex;
   height: 100%;
   align-items: center;
`;

const DropdownContent = styled.div<{ isOpen: boolean }>`
   position: absolute;
   top: calc(100% - 10px);
   right: 8px;
   min-width: 200px;
   color: black;
   box-shadow: ${shadow[3]};
   padding: 0;
   border-radius: 8px;
   overflow: auto;
   background-color: white;
   display: ${props => (props.isOpen ? 'flex' : 'none')};
   z-index: 1000;

   > div {
      flex: 1 1 auto;
      justify-content: center;
   }
`;

interface WrappedProps {
   id: string;
   href: string;
   onClick: MouseEventHandler;
   onKeyDown: KeyboardEventHandler;
   children: ReactNode;
}

function withDropdown<ClickableElementProps>(
   ClickableElement: ComponentType<WrappedProps & ClickableElementProps>
) {
   type ResultProps = {
      children: ReactNode;
      id?: string;
      content: ReactNode;
      clickableElementProps?: ClickableElementProps;
      onMount?: () => void;
      offset?: number[];
      onShow?: () => void;
      onHide?: () => void;
   };

   const DropdownWrappedComponent = ({
      onMount,
      content,
      children,
      id,
      clickableElementProps,
   }: ResultProps) => {
      const [isOpen, setIsOpen] = useState(false);
      const dropdownRef = useRef<HTMLDivElement>(null);
      const [closeTimeout, setCloseTimeout] = useState<NodeJS.Timeout | null>(null);

      useEffect(() => {
         if (isOpen && onMount) {
            onMount();
         }
      }, [isOpen, onMount]);

      const handleClick = (e: MouseEvent) => {
         e.preventDefault();
         e.stopPropagation();
         setIsOpen(!isOpen);
      };

      const handleMouseLeave = () => {
         const timeout = setTimeout(() => {
            setIsOpen(false);
         }, 500);
         setCloseTimeout(timeout);
      };

      const handleMouseEnter = () => {
         if (closeTimeout) {
            clearTimeout(closeTimeout);
         }
      };

      return (
         <DropdownContainer
            ref={dropdownRef}
            onMouseLeave={handleMouseLeave}
            onMouseEnter={handleMouseEnter}
         >
            {/* @ts-expect-error TS(2322) FIXME: Type 'StoreType | undefined' is not assignable to ... Remove this comment to see the full error message */}
            <ClickableElement
               {...clickableElementProps}
               id={id}
               onClick={handleClick}
               onKeyDown={e => e.preventDefault()}
            >
               {children}
            </ClickableElement>
            <DropdownContent isOpen={isOpen}>{content}</DropdownContent>
         </DropdownContainer>
      );
   };

   DropdownWrappedComponent.displayName = `withDropdown(${ClickableElement.displayName || ClickableElement.name || 'Component'})`;
   return DropdownWrappedComponent;
}

export default withDropdown;
