import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { color, space, lineHeight, fontSize, fontWeight } from '@ifixit/primitives';

const transitionDuration = '0.2s';

const Title = styled.p`
   color: ${color.white};
   margin: ${space[0]};
   font-size: ${fontSize.lg};
   font-weight: ${fontWeight.semiBold};
`;

const Divider = styled.div`
   height: 4px;
   margin: ${space[4]} 0;
   background: ${color.gray[600]};
   border-radius: 2px;
   transition: all ${transitionDuration} ease;
`;

const Description = styled.p`
   color: ${color.gray[400]};
   margin: ${space[0]};
   font-size: ${fontSize.md};
   font-weight: ${fontWeight.normal};
   line-height: ${lineHeight.normal};
`;

const Arrow = styled(FontAwesomeIcon)`
   margin: ${space[0]} auto;
   color: white;
   opacity: 0;
   position: absolute;
   left: calc(50% - 12px);
   bottom: 0px;
   transition: all ${transitionDuration} ease;
`;

const Container = styled.a`
   display: flex;
   position: relative;
   flex-direction: column;
   justify-content: space-between;
   min-width: 222px;
   padding: ${space[5]};
   transition: all ${transitionDuration} ease;

   &:hover {
      background: ${color.brand[500]};
      transform-origin: top;
      box-shadow: ${space[0]} ${space[5]} ${space[0]} ${space[0]} ${color.brand[500]};
      text-decoration: none;

      ${Divider} {
         background: ${color.white};
      }

      ${Description} {
         color: ${color.white};
      }

      svg {
         opacity: 1;
         bottom: -12px;
      }
   }
`;

const MenuItem = ({
   title = 'Untitled Sublink',
   description = 'No description',
   url = '/',
}: {
   title?: string;
   description?: string;
   url?: string;
}) => (
   <Container href={url} onKeyDown={e => e.stopPropagation()}>
      <div>
         <Title>{title}</Title>
         <Divider />
         <Description>{description}</Description>
      </div>
      <Arrow icon={faArrowRight} size="xl" focusable={false} />
   </Container>
);

export default MenuItem;
