import { useContext } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { color } from '@ifixit/primitives';
import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons/faMagnifyingGlass';
import { AppProvider } from '@ifixit/app/hooks';
import { FaIcon, FlagCountryCode } from '@ifixit/icons';
import { ShopifyStorefrontProvider } from '@ifixit/shopify-storefront-client';
import { CartDrawerProvider } from '@ifixit/ui-chakra';
import { ChakraDefaultProvider } from 'Shared/chakra-provider';
import { BreakpointsContext } from 'Shared/utils/breakpoints';
import * as HeaderActions from 'Shared/Header/actions';
import UserDropdown from './user_dropdown';
import AuthIcons from './auth_icons';
import { CartDrawerWrapper } from './cart_drawer_wrapper';
import WorkbenchDropdown from './workbench_dropdown';
import LanguageDropdownContainer from './language_dropdown_container';
import NotificationsDropdown from './notifications_dropdown';
import withNotifications from './with_notifications';
import { SmartLink } from 'Shared/smart_link';
import { LocaleCode } from '@ifixit/i18n';

const SearchButton = styled.button`
   color: ${color.white}
   display: flex;

   svg {
      transition: color 0.1s;
   }

   &:hover svg {
      color: ${color.gray[200]};
   }
`;

type TeamType = {
   teamLink: string;
   teamCount: number;
};

export type UserInfoType = {
   username: string;
   uniqueUsername: string;
   profileUrl: string;
   ordersUrl: string;
   notificationsUrl: string;
   avatar: string;
   team: TeamType;
   canIViewManageTab: boolean;
};

const UserLogin = ({ userInfo }: { userInfo: UserInfoType }) =>
   userInfo ? (
      <ConnectedUserDropdown
         username={userInfo.username}
         uniqueUsername={userInfo.uniqueUsername}
         profileUrl={userInfo.profileUrl}
         ordersUrl={userInfo.ordersUrl}
         notificationsUrl={userInfo.notificationsUrl}
         avatar={userInfo.avatar}
         team={userInfo.team}
         canIViewManageTab={userInfo.canIViewManageTab}
      />
   ) : (
      <AuthIcons />
   );

const ConnectedNotificationsDropdown = withNotifications(NotificationsDropdown);
const ConnectedUserDropdown = withNotifications(UserDropdown);

const mapDispatchToProps = (dispatch: any) => ({
   toggleMobileSearch: (value: any) => dispatch(HeaderActions.toggleMobileSearch(value)),
});

type DropdownsType = {
   userInfo: any;
   languageDropdown: any;
   shopifyStore: {
      currencyCode: string;
      storeCode: string;
      shopDomain: string;
      storefrontAccessToken: string;
      cartUrl: string;
      storeUrl: string;
   };
   cartDrawer: {
      flagCountryCode: FlagCountryCode;
      locale: LocaleCode | null;
   };
   user: {
      priceTiers: Record<string, string> | null;
   };
};

const NavIcons = ({
   dropdowns,
   isOnCart,
   toggleMobileSearch,
}: {
   dropdowns: DropdownsType;
   isOnCart: any;
   toggleMobileSearch?: (val: boolean) => void;
}) => {
   const breakpoints = useContext(BreakpointsContext);

   const showMobileSearch = () => {
      if (toggleMobileSearch) {
         toggleMobileSearch(true);
         (document.querySelector('#mobileSearchForm') as HTMLElement).focus();
      }
   };

   const { languageDropdown, userInfo, shopifyStore, cartDrawer, user } = dropdowns;
   const { languages, translationPreferencesUrl } = languageDropdown;
   const priceTiers = user.priceTiers ?? {};

   if (breakpoints.isMobileTablet()) {
      return (
         <AppProvider ifixitOrigin={window.origin} priceTiers={priceTiers}>
            <ShopifyStorefrontProvider {...shopifyStore}>
               <CartDrawerProvider>
                  {breakpoints.isMobile() && (
                     <SearchButton aria-label="Search" role="button" onClick={showMobileSearch}>
                        <FaIcon
                           icon={faMagnifyingGlass}
                           focusable="false"
                           color={color.white}
                           boxSize={{ base: '20px', sm: '24px' }}
                        />
                     </SearchButton>
                  )}
                  <ChakraDefaultProvider>
                     <CartDrawerWrapper
                        flagCountryCode={cartDrawer.flagCountryCode}
                        locale={cartDrawer.locale}
                        storeCode={shopifyStore.storeCode}
                        linkComponent={SmartLink}
                     />
                  </ChakraDefaultProvider>
                  <UserLogin userInfo={userInfo} />
               </CartDrawerProvider>
            </ShopifyStorefrontProvider>
         </AppProvider>
      );
   }

   return (
      <AppProvider ifixitOrigin={window.origin} priceTiers={priceTiers}>
         <ShopifyStorefrontProvider {...shopifyStore}>
            <CartDrawerProvider>
               <WorkbenchDropdown />
               {userInfo && (
                  <ConnectedNotificationsDropdown notificationsUrl={userInfo.notificationsUrl} />
               )}
               <ChakraDefaultProvider>
                  <CartDrawerWrapper
                     flagCountryCode={cartDrawer.flagCountryCode}
                     locale={cartDrawer.locale}
                     storeCode={shopifyStore.storeCode}
                     linkComponent={SmartLink}
                  />
               </ChakraDefaultProvider>
               {!isOnCart && (
                  <LanguageDropdownContainer
                     languages={languages}
                     translationPreferencesUrl={translationPreferencesUrl}
                  />
               )}
               <UserLogin userInfo={userInfo} />
            </CartDrawerProvider>
         </ShopifyStorefrontProvider>
      </AppProvider>
   );
};

export default connect(null, mapDispatchToProps)(NavIcons);
