import StoreFlag from './store_flag';
import styled from 'styled-components';
import { color, space, fontSize } from '@ifixit/primitives';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import withTippy from '../../dropdowns/with_tippy';
import StoreDropdown from './store_dropdown';
import trackInPiwikAndGA from 'Shared/Analytics/CombinedGAPiwik';
import { StoreType } from '..';

const StyledCaretDown = styled(FontAwesomeIcon)`
   color: ${color.gray[600]};
`;

const StyledCaretUp = styled(FontAwesomeIcon)`
   color: ${color.white};
`;

const Button = styled.a`
   display: flex;
   align-items: center;
   padding-right: ${space[5]};

   &:hover {
      text-decoration: none;

      ${StyledCaretDown} {
         color: ${color.white};
      }
   }
`;

const ButtonWithTippy = withTippy(Button);

const Currency = styled.div`
   font-style: normal;
   font-weight: bold;
   font-size: ${fontSize.sm};
   line-height: 1;
   color: ${color.white};
   margin-right: ${space[2]};
`;

const StoreSelector = ({
   stores,
   dropdownOpen,
   setDropdownOpen,
}: {
   stores: StoreType[];
   dropdownOpen: boolean;
   setDropdownOpen: (state: boolean) => void;
}) => {
   const currentStore = stores.find((store: StoreType) => store.isDisplayStore);

   return (
      <ButtonWithTippy
         id="storeDropdownToggle"
         offset={[90, -20]}
         onShow={() => {
            trackInPiwikAndGA({
               eventCategory: 'Preferred Store',
               eventAction: 'Preferred Store Dropdown - Click',
            });
            setDropdownOpen(true);
         }}
         onHide={() => setDropdownOpen(false)}
         // @ts-expect-error TS(2322) FIXME: Type 'StoreType | undefined' is not assignable to ... Remove this comment to see the full error message
         content={<StoreDropdown currentStore={currentStore} stores={stores} />}
      >
         <StoreFlag
            // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
            storeCode={currentStore.storeCode}
            style={{
               marginRight: '8px',
            }}
            id="currentStore"
         />
         {/* @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'. */}
         <Currency>{currentStore.currency}</Currency>
         {dropdownOpen ? (
            <StyledCaretUp icon={faCaretUp} />
         ) : (
            <StyledCaretDown icon={faCaretDown} />
         )}
      </ButtonWithTippy>
   );
};

export default StoreSelector;
