import type { MouseEventHandler, ReactNode } from 'react';
import styled from 'styled-components';
import { color, space } from '@ifixit/primitives';

const Container = styled.a`
   display: flex;
   align-items: center;
   padding: 8px ${space[4]};
   font-weight: normal;
   font-size: 14px;
   color: ${color.gray[800]};

   &:hover {
      background: ${color.gray[50]};
      text-decoration: none;
   }
`;

const Text = styled.span`
   flex: 1;
   margin: 0;
   padding: 0px;
   font-size: 14px;
   font-weight: normal;
   color: ${color.gray[800]};
   line-height: 1.21em;
`;

const Badge = styled.span`
   margin: 0;
   font-size: 14px;
   font-weight: normal;
   color: ${color.brand[500]};
   text-align: right;
   line-height: 1.21em;
`;

interface MenuButtonProps {
   id?: string;
   onClick?: MouseEventHandler;
   className?: string;
   text: string;
   badge?: ReactNode;
   url: string;
}

const MenuButton = ({ id, onClick, className, text, badge, url }: MenuButtonProps) => (
   <Container onClick={onClick} className={className} href={url}>
      <Text id={id}>{text}</Text>
      {badge && <Badge>{badge}</Badge>}
   </Container>
);

export default MenuButton;
