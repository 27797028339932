import { useCallback, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { color, borderRadius, space, fontWeight } from '@ifixit/primitives';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';

const Panel = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;
   height: 48px;
   padding: 16px;
   cursor: pointer;
   border-radius: ${borderRadius.md};
   transition: all 0.2s;
   border-bottom: 1px solid ${color.gray[800]};
`;

const PanelTitle = styled.span`
   margin: 0;
   padding: 0px;
   color: white;
   font-weight: ${fontWeight.semiBold};
   font-size: 18px;
   line-height: 1.21em;
`;

const ChevronContainer = styled.div`
   transition: all 0.25s;
`;

const SubitemsContainer = styled.div`
   padding: ${space[2]} ${space[4]} 0;

   .subitem {
      visibility: hidden;
      opacity: 0;
      height: 0px;
      transition: all 0.25s;

      a {
         display: flex;
         align-items: center;
         text-align: left;
      }
   }
`;

const Container = styled.div<{ $isActive?: boolean }>`
   position: relative;
   min-height: 48px;
   padding: 0 12px;

   ${props =>
      props.$isActive &&
      css`
         ${Panel} {
            background: ${color.brand[500]};
            border-bottom: 1px solid transparent;
            outline: none;
         }

         ${ChevronContainer} {
            transform: rotate(180deg);
         }

         .subitem {
            visibility: visible;
            opacity: 1;
            height: ${space[7]};
         }
      `};
`;

const AccordionItem = ({
   title,
   isActive,
   onClick,
   children,
}: {
   title: string;
   isActive?: boolean;
   onClick: (title: string) => void;
   children: ReactNode;
}) => {
   const panelClicked = useCallback(() => onClick(title), [onClick, title]);
   const panelKeyed = useCallback(
      (e: any) => (e.key === 'Enter' || e.key === ' ') && onClick(title),
      [onClick, title]
   );

   return (
      <Container $isActive={isActive}>
         <Panel onClick={panelClicked} onKeyDown={panelKeyed} tabIndex={0}>
            <PanelTitle>{title}</PanelTitle>
            <ChevronContainer>
               <FontAwesomeIcon
                  icon={faChevronDown}
                  color={isActive ? color.white : color.gray[500]}
                  focusable="false"
               />
            </ChevronContainer>
         </Panel>
         <SubitemsContainer>{children}</SubitemsContainer>
      </Container>
   );
};

export default AccordionItem;
