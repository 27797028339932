import { _js } from '@ifixit/localize';
import { useEffect } from 'react';
import WorkbenchManager from 'Shared/workbench_manager';
import WorkbenchItem from 'Shared/workbench_item';
import styled from 'styled-components';
import { color, space, fontSize } from '@ifixit/primitives';
import { DevicePickerModal } from '@ifixit/device-picker';
import API_2_0 from 'Shared/api_2_0';
import * as HeaderActions from 'Shared/Header/actions';
import { store } from 'Shared/react-initialize';
import { Button, useDisclosure } from '@chakra-ui/react';
import { getSearchClient } from 'Guide/searchClient';

const WorkbenchContainer = styled.div`
   text-align: left;
   padding: ${space[0]};
   overflow: hidden;
   width: 350px;
`;

const WorkbenchHeaderContainer = styled.div`
   padding: ${space[4]} ${space[0]};
   text-align: center;
   background-color: ${color.coolGray[50]};
`;

const WorkbenchHeader = styled.span`
   text-transform: uppercase;
   font-weight: 700;
   font-size: ${fontSize.lg};
   line-height: 1;
   color: ${color.gray[600]};
`;

const WorkbenchTopicContainer = styled.div`
   max-height: 42vh;
   overflow: auto;
   background-color: ${color.white};
`;

const WorkbenchEmptyMessage = styled.p`
   padding: ${space[5]} ${space[7]};
   margin: ${space[0]};
   font-size: ${fontSize.lg};
   text-align: center;
`;

const WorkbenchAddTopicContainer = styled.div`
   width: 100%;
   height: 72px;
   padding: ${space[4]};
   background-color: ${color.coolGray[50]};
   border-top: 1px solid ${color.gray[300]};
`;

const TopicList = ({ topics }) =>
   topics.map(topic => <WorkbenchItem key={`${topic.wikiid}-${topic.variantid}`} topic={topic} />);

const EmptyTopicsMessage = () => (
   <WorkbenchEmptyMessage id="workbench-empty-message">
      {_js('Get quick access to guides, parts and answers for your devices')}
   </WorkbenchEmptyMessage>
);

const Workbench = ({ topics, onTippyToggle }) => {
   useEffect(WorkbenchManager.getCurrentWorkbench, []);

   // if the workbench does not receive topics (loading) do not render anything
   if (!topics) {
      return <WorkbenchContainer id="loading-workbench"></WorkbenchContainer>;
   }

   const hasTopics = topics.length !== 0;
   const Topics = hasTopics ? TopicList : EmptyTopicsMessage;

   return (
      <WorkbenchContainer id="workbench">
         <WorkbenchHeaderContainer>
            <WorkbenchHeader>{_js('My Workbench')}</WorkbenchHeader>
         </WorkbenchHeaderContainer>
         <WorkbenchTopicContainer id="workbench-topic-container">
            <Topics topics={topics} />
         </WorkbenchTopicContainer>
         <WorkbenchAddTopicContainer>
            <WorkbenchDevicePickerModal devicesInWorkbench={topics} onTippyToggle={onTippyToggle} />
         </WorkbenchAddTopicContainer>
      </WorkbenchContainer>
   );
};

export default Workbench;

const WorkbenchDevicePickerModal = ({ devicesInWorkbench, onTippyToggle }) => {
   const { isOpen, onOpen, onClose } = useDisclosure();

   const selectDevice = device => {
      if (isInWorkbench(device, devicesInWorkbench)) {
         removeDeviceFromWorkbench(device.device, device.variant);
      } else {
         WorkbenchManager.addItemWithVariantString(
            device.wikiid,
            device.device,
            device.variant || null,
            /* showIndicator */ true
         );
      }
   };

   const removeDeviceFromWorkbench = (deviceTitle, variantString) => {
      const request = new API_2_0('user/workbench/removeWithVariantString', {
         method: 'POST',
      })
         .send({
            device: deviceTitle,
            variant: variantString || null,
         })
         .then(response => {
            store.dispatch(HeaderActions.updateWorkbench(response.topics));
         });

      LoadingIndicator.withPromise(request, {
         pendingMessage: _js('Removing'),
         successMessage: _js('Removed'),
      });
   };

   const openModal = () => {
      onTippyToggle();
      onOpen();
   };

   const closeModal = () => {
      onTippyToggle();
      onClose();
   };

   return (
      <>
         <Button
            data-test-id="workbench-add-device"
            variant="cta"
            fontWeight="semibold"
            borderRadius="4px"
            width="100%"
            py={2}
            height="38px"
            onClick={openModal}
         >
            {_js('Add a Device')}
         </Button>
         <DevicePickerModal
            isOpen={isOpen}
            onClose={closeModal}
            onSelect={selectDevice}
            algoliaConfig={{
               searchClient: getSearchClient(),
               indexPrefix: App.algoliaConfig['indexPrefix'],
            }}
            oniFixit={App.which === 'ifixit'}
            langid={App.lang}
            preferredStore={App.userPreferredStoreCode}
            devicesInWorkbench={devicesInWorkbench}
            titleInstruction={_js('Select a model')}
            selectionProps={'allowSelectingVariants'}
            analyticsKey={'Workbench'}
         />
      </>
   );
};

function isInWorkbench(device, devicesInWorkbench) {
   return devicesInWorkbench.some(wbDevice => {
      const wikiidMatches = wbDevice.wikiid === device.wikiid;
      const variantidMatches = device.variantid === wbDevice.variantid;
      const noVariantIds = !device.variantid && !wbDevice.variantid;
      return wikiidMatches && (variantidMatches || noVariantIds);
   });
}
