import { useContext, useCallback } from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import { space, shadow } from '@ifixit/primitives';
import { withBreakpoints, BreakpointsContext } from 'Shared/utils/breakpoints';
import FocusLock from 'react-focus-lock';
import { RemoveScroll } from 'react-remove-scroll';
import {
   Logo,
   MenuIcon,
   Headings,
   SearchBar,
   MobileSearch,
   MobileMenu,
   Dropdowns,
} from './components';
import * as HeaderActions from './actions';
import { useModalIsOpen } from './shared-state';

// Mirror header style changes in `ifixit_minimal_header_styles.css` to avoid CLS

const HeaderWrapper = styled.div`
   position: relative;
   background: #11161a;
   box-shadow: ${shadow[2]};
   z-index: 100;
`;

interface HeaderProps {
   breakpoints: any;
   $inMobileSearch?: boolean;
}

const HeaderContainer = withBreakpoints(styled.div.attrs(() => ({
   className: 'mainHeader',
}))<HeaderProps>`
   position: relative;
   display: flex;
   background-color: #11161a;
   height: 68px;
   max-width: 1400px;
   margin: 0 auto;
   padding: 0 ${space[3]};

   @media (min-width: 1440px) {
      padding: 0;
   }

   ${props =>
      props.breakpoints.isMobile() &&
      props.$inMobileSearch &&
      css`
         ${Section} {
            opacity: 0;
            transform: translateY(100%);
            pointer-events: none;
            visibility: hidden;
         }
      `}
`);

const LogoContainer = styled.a`
   display: flex;
   margin-inline: ${space[4]};

   @media (max-width: 374px) {
      margin-inline: ${space[3]};
   }
`;

const Section = styled.div<{ $right?: boolean }>`
   display: flex;
   align-items: center;
   transition: all 0.25s;

   ${props =>
      props.$right &&
      css`
         flex: 1;
         justify-content: flex-end;
      `}
`;

const LogoComponent = () => (
   <LogoContainer href="/" aria-label="home page">
      <Logo />
   </LogoContainer>
);

const mapStateToProps = (state: any) => ({
   headerState: state.headerState,
});

const mapDispatchToProps = {
   toggleMobileMenu: HeaderActions.toggleMobileMenu,
};

const Header = (props: any) => {
   const { headerState, isOnCart, links, stores, dropdowns, toggleMobileMenu } = props;

   const breakpoints = useContext(BreakpointsContext);

   const [modalIsOpen] = useModalIsOpen();

   const mobileMenuClicked = useCallback(() => {
      toggleMobileMenu();
      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      document.getElementById('menuIcon').focus();
   }, [toggleMobileMenu]);

   if (breakpoints.isMobileTablet()) {
      return (
         <FocusLock disabled={!!(!headerState.displayMobileMenu || modalIsOpen)}>
            <RemoveScroll enabled={headerState.displayMobileMenu && !modalIsOpen}>
               <HeaderContainer $inMobileSearch={headerState.displayMobileSearch}>
                  {breakpoints.isMobile() && <MobileSearch isOnCart={isOnCart} />}
                  <Section>
                     <MenuIcon
                        isActive={headerState.displayMobileMenu}
                        onClick={mobileMenuClicked}
                     />
                     <LogoComponent />
                  </Section>
                  <Section $right>
                     {breakpoints.isTablet() && <SearchBar isOnCart={isOnCart} />}
                     <Dropdowns dropdowns={dropdowns} isOnCart={isOnCart} />
                  </Section>
                  <MobileMenu
                     links={links}
                     languageDropdown={dropdowns.languageDropdown}
                     stores={stores}
                     isOnCart={isOnCart}
                  />
               </HeaderContainer>
            </RemoveScroll>
         </FocusLock>
      );
   }

   return (
      <HeaderWrapper>
         <HeaderContainer>
            <Section>
               <LogoComponent />
            </Section>
            <Headings links={links} stores={stores} />
            <Section $right>
               <SearchBar isOnCart={isOnCart} />
               <Dropdowns dropdowns={dropdowns} isOnCart={isOnCart} />
            </Section>
         </HeaderContainer>
      </HeaderWrapper>
   );
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
