import { _js } from '@ifixit/localize';
import { faBell } from '@fortawesome/pro-solid-svg-icons/faBell';
import Dropdown from './dropdown';
import NotificationsDisplay from './notifications_display';
import Notifications from 'Shared/Notifications/notifications';

function NotificationsDropdown({
   notifications,
   notificationsUrl,
}: {
   notifications: Notifications;
   notificationsUrl: string;
}) {
   const unreadNotifications = notifications.getUnreadNotifications();
   const hasUnread = unreadNotifications.length != 0;

   const label = hasUnread ? 'unread notifications dropdown' : 'notifications dropdown';

   return (
      <Dropdown label={label} iconName={faBell} hasBadge={hasUnread}>
         {hasUnread ? (
            <NotificationsDisplay
               unreadNotifications={unreadNotifications}
               notificationsUrl={notificationsUrl}
               markAllRead={notifications.markAllRead}
            />
         ) : (
            <div
               style={{
                  display: 'flex',
                  padding: '8px 12px',
               }}
            >
               {_js('No notifications!')}
            </div>
         )}
      </Dropdown>
   );
}

export default NotificationsDropdown;
