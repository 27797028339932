import { HStack } from '@chakra-ui/react';
import {
   BlueskyLogo,
   FacebookLogo,
   InstagramLogo,
   MastodonLogo,
   RepairOrgLogo,
   RepairEULogo,
   TwitterLogo,
   TiktokLogo,
   YoutubeLogo,
} from '@ifixit/icons';
import { memo } from 'react';
import { FooterLink } from './Shared';

const socialMediaAccounts = {
   tiktok: {
      url: 'https://tiktok.com/@ifixit.com',
      icon: TiktokLogo,
      ariaLabel: 'TikTok',
   },
   facebook: {
      url: 'https://www.facebook.com/iFixit/',
      icon: FacebookLogo,
      ariaLabel: 'Facebook',
   },
   twitter: {
      url: 'https://twitter.com/ifixit',
      icon: TwitterLogo,
      ariaLabel: 'Twitter',
   },
   instagram: {
      url: 'https://www.instagram.com/ifixit/',
      icon: InstagramLogo,
      ariaLabel: 'Instagram',
   },
   youtube: {
      url: 'https://www.youtube.com/user/iFixitYourself',
      icon: YoutubeLogo,
      ariaLabel: 'YouTube',
   },
   mastodon: {
      url: 'https://mastodon.social/@iFixit',
      icon: MastodonLogo,
      ariaLabel: 'Mastodon',
   },
   bluesky: {
      url: 'https://bsky.app/profile/ifixit.com',
      icon: BlueskyLogo,
      ariaLabel: 'Bluesky',
   },
};

export interface SocialMediaAccounts {
   repairOrg: string | null;
}

export const SocialMediaSection = memo(function SocialMediaSection({
   accounts,
   repairUrl,
}: {
   accounts: SocialMediaAccounts;
   repairUrl?: string;
}) {
   return (
      <HStack spacing={4} justify={{ base: 'space-between', sm: 'center' }}>
         {Object.entries(socialMediaAccounts).map(([key, value]) => (
            <FooterLink
               key={key}
               aria-label={value.ariaLabel}
               href={value.url}
               icon={value.icon}
               customColor={'gray.400'}
            />
         ))}
         {accounts.repairOrg && (
            <FooterLink
               aria-label="The Repair Association"
               href={accounts.repairOrg}
               icon={repairUrl && repairUrl.includes('eu') ? RepairEULogo : RepairOrgLogo}
               customColor={'gray.400'}
            />
         )}
      </HStack>
   );
});
