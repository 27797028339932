import { ReactNode } from 'react';
import type { FC } from 'react';
import { FaIcon } from '@ifixit/icons';
import { color, space } from '@ifixit/primitives';
import styled from 'styled-components';
import withTippy from './with_tippy';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const UnstyledButton = styled.button`
  display: flex;
  position: relative;
  overflow: visible;
  margin-inline: ${space[4]};
  padding: 0;
  height: 100%;
  align-items: center;

  svg:first-child {
    transition: color 0.1s;
  }

  &:hover {
    text-decoration: none;
    svg:first-child {
      color: ${color.gray[200]};
    }
  }

  @media (max-width: 375px) {
    margin-inline: ${space[2]};
  }
`;

const Badge = styled.div`
  position: absolute;
  top: 18px;
  right: -4px;
  border: 2px solid #11161a;
  border-radius: 99em;
  width: 0.875rem;
  height: 0.875rem;
  background-color: ${color.brand[500]};
`;

const UnstyledButtonWithTippy = withTippy(UnstyledButton);

interface PopperProps {
   content?: ReactNode;
   placement?: 'top' | 'bottom' | 'left' | 'right';
   delay?: number | [number, number];
   duration?: number | [number, number];
   trigger?: 'click' | 'hover' | 'focus';
   interactive?: boolean;
   visible?: boolean;
   onClickOutside?: (instance: any) => void;
   appendTo?: 'parent' | HTMLElement;
}

interface DropdownProps extends Omit<PopperProps, 'children' | 'content'> {
   id?: string;
   icon?: ReactNode;
   iconName?: IconProp;
   hasBadge?: boolean;
   label: string;
   children: PopperProps['content'];
   onMount?: () => void;
   hideOnClick?: boolean;
}

const Dropdown: FC<DropdownProps> = ({
   id,
   children,
   icon,
   iconName,
   hasBadge = false,
   label,
   ...tippyOptions
}) => {
   const passthroughProps = {
      'aria-label': label,
      role: 'button',
   };

   return (
      <UnstyledButtonWithTippy
         id={id}
         content={children}
         clickableElementProps={passthroughProps}
         onMount={() => {}}
         {...tippyOptions}
      >
         {iconName ? <FaIcon icon={iconName} color={color.white} boxSize="24px" /> : icon}
         {hasBadge && <Badge className="headerBadge" />}
      </UnstyledButtonWithTippy>
   );
};

export default Dropdown;
