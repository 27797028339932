import { Box, HTMLChakraProps, LinkProps, forwardRef as chakraForwardRef } from '@chakra-ui/react';
import { isAbsoluteHref, isNextJsPath } from '@ifixit/helpers';
import { LocaleCode, isLocaleCode } from '@ifixit/i18n';

type SmartLinkProps = HTMLChakraProps<'a'> & WrappedLinkProps;

type WrappedLinkProps = Omit<LinkProps, 'as'> & { nextAs?: string; localeCode?: LocaleCode };

export const SmartLink = chakraForwardRef<SmartLinkProps, 'a'>(
   ({ href, localeCode, ...other }, ref) => {
      if (!href) {
         return null;
      }
      const localizedHref = localizeUrl(href, localeCode);

      return <Box as="a" ref={ref} href={localizedHref} {...other} />;
   }
);

function localizeUrl(url: string, localeCode?: LocaleCode): string {
   // we dont need to localize us links or absolute links
   // this doesn't localize non-nextjs paths
   if (!localeCode || localeCode === 'en-us' || isAbsoluteHref(url) || !isNextJsPath(url))
      return url;

   const subPaths = url.split('/').filter(Boolean);
   const firstSubDir = subPaths.shift();
   if (firstSubDir) {
      if (!isLocaleCode(firstSubDir)) {
         subPaths.unshift(firstSubDir);
      }
      subPaths.unshift(localeCode);
   }

   const localizedUrl = subPaths.join('/');
   return url.startsWith('/') ? `/${localizedUrl}` : localizedUrl;
}
