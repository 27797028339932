import type { BoxProps, FlexProps, ListItemProps, SimpleGridProps } from '@chakra-ui/react';
import {
   Box,
   Flex,
   List,
   ListIcon,
   ListItem,
   ListProps,
   SimpleGrid,
   Text,
   TextProps,
   forwardRef,
} from '@chakra-ui/react';
import type { MenuItem, Menu as MenuType } from '@ifixit/menu';
import { useTrackedOnClick } from '@ifixit/tracking-hooks';
import type { ReactElement, ReactNode, SVGProps } from 'react';
import { connect } from 'react-redux';
import type { NewsletterFormProps } from './Newsletter';
import { NewsletterComponent } from './Newsletter';

export type NavSectionProps = {
   menu1: MenuType | null;
   menu2: MenuType | null;
   menu3: MenuType | null;
   newsletterForm: NewsletterFormProps | null;
   newsletterLinkMessageSubscribed: string;
   newsletterLinkMessageUnsubscribed: string;
   storeCode: string;
   updateConsentMessage: string;
};

export const NavigationSection = ({
   menu1,
   menu2,
   menu3,
   newsletterForm,
   newsletterLinkMessageSubscribed,
   newsletterLinkMessageUnsubscribed,
   storeCode,
   updateConsentMessage,
}: NavSectionProps) => (
   <FooterTopSection>
      <FooterNavigationSection>
         <FooterNavigationList>
            <NavigationColumn menu={menu1} />
         </FooterNavigationList>
         <FooterNavigationList>
            <NavigationColumn menu={menu2} />
         </FooterNavigationList>
         <FooterNavigationList>
            <NavigationColumn menu={menu3} updateConsentMessage={updateConsentMessage} />
         </FooterNavigationList>
      </FooterNavigationSection>
      {newsletterForm && (
         <NewsletterComponent
            newsletterForm={newsletterForm}
            linkMessageSubscribed={newsletterLinkMessageSubscribed}
            linkMessageUnsubscribed={newsletterLinkMessageUnsubscribed}
            storeCode={storeCode}
         />
      )}
   </FooterTopSection>
);

export const NavigationColumn = ({
   menu,
   listFontSize = 'sm',
   updateConsentMessage,
   ...otherProps
}: {
   menu: MenuType | null;
   listFontSize?: TextProps['fontSize'];
   updateConsentMessage?: string;
} & TextProps) => {
   if (!menu) {
      return null;
   }
   return (
      <>
         <Text fontSize="sm" fontWeight="semibold" color="white" mb="3" mt="0" {...otherProps}>
            {menu.title}
         </Text>
         <FooterNavigationListItems
            menu={menu}
            fontSize={listFontSize}
            updateConsentMessage={updateConsentMessage}
         />
      </>
   );
};

const StyledFooterListItem = ({
   children,
   href,
   ...props
}: { children: ReactNode; href?: string } & TextProps) => (
   <FooterNavigationItem>
      <FooterNavigationLink
         fontSize="sm"
         fontWeight="normal"
         m={0}
         p={0}
         color="gray.300"
         _visited={{ color: 'gray.300' }}
         _hover={{ color: 'white', textDecoration: 'none' }}
         sx={{
            '&:visited:hover': {
               color: 'white',
            },
         }}
         href={href}
         {...props}
      >
         {children}
      </FooterNavigationLink>
   </FooterNavigationItem>
);

export const FooterNavigationListItems = ({
   menu,
   updateConsentMessage,
   ...otherProps
}: { menu: MenuType; updateConsentMessage?: string } & TextProps) => {
   const listItems = menu.items.map((item: MenuItem, index: number) => {
      if (item.type !== 'link') {
         return null;
      }
      return (
         <StyledFooterListItem key={index} href={item.url} {...otherProps}>
            {item.name}
         </StyledFooterListItem>
      );
   });

   return (
      <>
         {listItems}
         {updateConsentMessage && (
            <UpdateConsentButton buttonText={updateConsentMessage} {...otherProps} />
         )}
      </>
   );
};

const stateToProps = (state: { consentBannerState: { bannerIsOpen: boolean } }) => ({
   bannerIsOpen: state.consentBannerState.bannerIsOpen,
});
const dispatchToProps = (
   dispatch: ({ type, value }: { type: string; value: unknown }) => void
) => ({
   setBannerIsOpen: (value: boolean) => dispatch({ type: 'TOGGLE_BANNER', value }),
   setDetailsIsOpen: (value: boolean) => dispatch({ type: 'TOGGLE_DETAILS', value }),
});

const UpdateConsentButton = connect(
   stateToProps,
   dispatchToProps
)(
   ({
      bannerIsOpen,
      setBannerIsOpen,
      setDetailsIsOpen,
      buttonText,
      ...otherProps
   }: {
      bannerIsOpen: boolean;
      setBannerIsOpen: (val: boolean) => void;
      setDetailsIsOpen: (val: boolean) => void;
      buttonText: string;
   } & TextProps) => (
      <StyledFooterListItem
         data-testid="footer-cookie-consent"
         onClick={() => {
            if (!bannerIsOpen) {
               setBannerIsOpen(true);
            }
            setDetailsIsOpen(true);
         }}
         {...otherProps}
      >
         {buttonText}
      </StyledFooterListItem>
   )
);

export const FooterTopSection = forwardRef<FlexProps, 'div'>((props, ref) => (
   <Flex
      ref={ref}
      pb={10}
      flexDirection={{
         base: 'column',
         md: 'row',
      }}
      flexWrap="wrap"
      justifyContent="space-between"
      {...props}
   />
));

export const FooterNavigationSection = forwardRef<SimpleGridProps, 'div'>((props, ref) => (
   <SimpleGrid
      ref={ref}
      columns={{
         base: 1,
         sm: 3,
      }}
      spacing="4"
      p="0"
      autoFlow="row"
      flex="1"
      {...props}
   />
));

export const FooterNavigationList = forwardRef<ListProps, 'ul'>(
   ({ children, ...otherProps }, ref) => (
      <List
         ref={ref}
         pb={{
            base: 4,
            sm: 0,
         }}
         m={{ base: 0 }}
         pl={0}
         {...otherProps}
      >
         {children}
      </List>
   )
);

export const FooterNavigationItem = (props: ListItemProps) => (
   <ListItem py="1" my="1" fontSize="sm" color="gray.300" {...props} />
);

type FooterNavigationLinkProps = BoxProps & {
   icon?: (props: SVGProps<SVGSVGElement>) => ReactElement;
};

export const FooterNavigationLink = forwardRef<FooterNavigationLinkProps, 'a'>(
   ({ icon, children, ...otherProps }, ref) => {
      const trackedOnClick = useTrackedOnClick({
         clickName: children?.toString(),
         ...otherProps,
      });
      return (
         <Box
            ref={ref}
            as="a"
            onClick={trackedOnClick}
            cursor="pointer"
            transition="all 400ms"
            fontWeight="normal"
            m={0}
            p={0}
            _hover={{
               color: 'white',
               textDecoration: 'none',
            }}
            {...otherProps}
         >
            {icon && <ListIcon as={icon} boxSize="4" filter="opacity(0.5)" />}
            {children}
         </Box>
      );
   }
);
