import type { ReactElement, ComponentType } from 'react';
import styled from 'styled-components';
import { color, borderRadius, space, fontWeight } from '@ifixit/primitives';
import withTippy from '../dropdowns/with_tippy';

const TitleContainer = styled.div`
   display: flex;
   align-items: center;
`;

const Indicator = styled.div`
   width: 4px;
   height: 30px;
   margin-right: ${space[3]};
   background: ${color.gray[800]};
   border-radius: ${borderRadius.sm};
   transition: background 0.2s;
`;

const Link = styled.a.attrs(_props => ({
   className: 'subitem',
}))`
   display: flex;
   align-items: center;
   justify-content: space-between;
   width: 100%;

   &:hover {
      text-decoration: none;

      ${Indicator} {
         background: ${color.brand[500]};
      }
   }

   span {
      margin: 0;
      padding: 0px;
      color: ${color.gray[300]};
      font-weight: ${fontWeight.normal};
      font-size: 18px;
      line-height: 1.21em;
   }
`;

const DropdownLink = withTippy(Link);

const FullWidthDropdownLink = styled(DropdownLink)`
   width: calc(100vw - 2 * ${space[4]});
   margin: 0 ${space[4]};
   right: 18px;
`;

interface AccordionSubitemDropdownProps {
   dropdown: ReactElement<any>;
   fullWidthDropdown: boolean;
   onMount?: () => void;
}

interface AccordionSubitemLinkProps {
   href: string;
}

interface LinkDescription<Props> {
   component: ComponentType<Props>;
   props: Props;
}

function getLink(
   props: AccordionSubitemDropdownProps | AccordionSubitemLinkProps
): LinkDescription<any> {
   if (!('dropdown' in props)) {
      return {
         component: Link,
         props,
      };
   }

   const { dropdown, fullWidthDropdown, onMount = () => {} } = props;

   const standardWidthDropdownProps = {
      content: dropdown,
      placement: 'top',
      onMount,
   };

   const fullWidthDropdownProps = {
      ...standardWidthDropdownProps,
      offset: [0, -280],
      maxWidth: 'none',
      placement: 'bottom',
   };

   if (fullWidthDropdown) {
      return {
         component: FullWidthDropdownLink,
         props: fullWidthDropdownProps,
      };
   }
   return {
      component: DropdownLink,
      props: standardWidthDropdownProps,
   };
}

interface AccordionSubitemProps {
   title: string;
   icon?: ReactElement<any>;
}

const AccordionSubitem = ({
   title,
   icon,
   ...props
}: (AccordionSubitemDropdownProps | AccordionSubitemLinkProps) & AccordionSubitemProps) => {
   const { component: Component, props: linkProps } = getLink(props);
   return (
      <Component {...linkProps}>
         <TitleContainer>
            <Indicator />
            <span>{title}</span>
         </TitleContainer>
         {icon}
      </Component>
   );
};

export default AccordionSubitem;
