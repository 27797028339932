import { _js } from '@ifixit/localize';
import { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { color } from '@ifixit/primitives';
import { relativeDate as getRelativeDate, MINUTE } from 'Shared/realtime_date';

const Icon = styled.i`
   width: 25px;
   margin-bottom: auto;

   text-align: center;
   line-height: 1.55em;

   opacity: 0.3;
   transition:
      color 0.2s,
      opacity 0.2s;
`;

const NotificationInfo = styled.div`
   display: flex;
   flex-flow: column nowrap;

   margin-left: 10px;
   flex: 1;

   text-align: left;
   overflow: hidden;
   overflow-wrap: break-word;

   time {
      opacity: 0.4;
   }

   &:hover,
   &:active {
      outline: none;
   }
`;

const NotificationLink = styled.a`
   display: flex;
   flex-flow: row nowrap;

   padding: 10px;

   color: ${color.gray[800]};

   &:hover {
      background-color: ${color.gray[50]};
      text-decoration: none;

      ${Icon} {
         opacity: 1;
         color: ${color.brand[400]};

         :last-child {
            :hover {
               color: ${color.brand[700]};
            }
         }
      }
   }
`;

const getIconClass = (icon: any) => `fa fa-${icon}`;

type MetaDataType = {
   date: string;
   url: string;
   icon: string;
   message: string;
};

const NotificationCard = ({
   metadata,
   markRead,
}: {
   metadata: MetaDataType;
   markRead: () => void;
}) => {
   const date = new Date(metadata.date);

   const [relativeDate, setRelativeDate] = useState(getRelativeDate(date));

   useEffect(() => {
      function updateRelativeDate() {
         setRelativeDate(getRelativeDate(date));
      }

      const timerID = setInterval(() => updateRelativeDate(), MINUTE);
      return function cleanup() {
         clearInterval(timerID);
      };
   }, []);

   const onClickGenerator = useCallback(
      (redirect: any) => (e: any) => {
         markRead();
         if (redirect) {
            window.open(metadata.url, '_blank');
         }
         e.stopPropagation();
      },
      [markRead, metadata.url]
   );

   const onKeyDownGenerator = useCallback(
      (redirect: any) => (e: any) => {
         if (e.key === 'Enter') {
            onClickGenerator(redirect)(e);
         }
      },
      [onClickGenerator]
   );

   return (
      <li>
         <NotificationLink onClick={onClickGenerator(true)} onKeyDown={onKeyDownGenerator(true)}>
            <Icon className={getIconClass(metadata.icon)} />
            <NotificationInfo tabIndex={0}>
               <div>{_js(metadata.message)}</div>
               <time title={metadata.date} dateTime={date.toISOString()}>
                  {relativeDate}
               </time>
            </NotificationInfo>
            <Icon
               tabIndex={0}
               className={getIconClass('times')}
               onClick={onClickGenerator(false)}
               onKeyDown={onKeyDownGenerator(false)}
            />
         </NotificationLink>
      </li>
   );
};

export default NotificationCard;
